/* bootstrap overwrite */
.newsletter-component {
  background-color: #f1f2f5;
  --focus-offset-color: #f1f2f5;
}
.newsletter-component .bordure {
  display: none;
  height: 20px;
  background-image: url("../images/bordure.svg");
}
.newsletter-component .form-control {
  background: #f8f9f9;
  color: #001f47;
  border: 1px solid #657785;
}
.newsletter-component .form-control::placeholder {
  color: #657785;
}