@import 'webresources-sources/scss/imports';

.newsletter-component {
  background-color: $color-darkgray-100;

  --focus-offset-color: #{$color-darkgray-100};

  .bordure {
    display: none;
    height: 20px;
    background-image: url('../images/bordure.svg');
  }

  .form-control {
    background: $color-gray-300;
    color: $color-darkblue;
    border: 1px solid $color-gray-600;

    &::placeholder {
      color: $color-gray-600;
    }
  }
}
